const EventService = [
  { title: 'Mastercard Alert', value: 'Mastercard Alert' },
  { title: 'Visa RDR', value: 'Visa RDR' },
  { title: 'Visa CDRN', value: 'Visa CDRN' },
];

export const EventServiceEnum = {
  VISA_RDR: 'Visa RDR',
  VISA_CDRN: 'Visa CDRN',
  MASTERCARD_ALERT: 'Mastercard Alert',
};

export const definedEventId = eventService => {
  let eventIdName = 'Alert ID';
  switch (eventService) {
    case 'Mastercard Alert':
      eventIdName = 'MasterCard ID';
      break;
    case 'Visa RDR':
      eventIdName = 'Visa RDR ID';
      break;
    case 'Visa CDRN':
      eventIdName = 'Visa CDRN ID';
      break;
    default:
  }
  return eventIdName;
};

export default EventService;
