import store from '@/store';
import { ref, watch } from '@vue/composition-api';
import Vue from 'vue';
import eventServiceEnum from '@/enums/event-service.enum';
import { getProfile } from '@/services/jwt.service';
import Roles from '@/enums/roles.enum';
import moment from 'moment';
import RolesEnum from '@/enums/roles.enum';

export default function useChargebackReportsList() {
  const isAdmin = getProfile().role === Roles.ADMIN;

  /**
   * Data options
   */
  const reportListTable = ref([]);

  let tableTotalCountColumns = ref([
    { text: 'Total', value: 'total', sortable: false, width: '200px' },
    { text: 'Events', value: 'totalEvents', sortable: false, width: '200px' },
    { text: 'Merchanto Gross', value: 'merchantoGross', sortable: false, width: '200px' },
    { text: 'Merchanto Spend', value: 'merchantoSpend', sortable: false, width: '200px' },
    { text: 'Resellers Fee', value: 'resellerFee', sortable: false, width: '200px' },
    { text: 'Revenue per event', value: 'revenuePerEvent', sortable: false, width: '200px' },
    { text: 'Revenue', value: 'revenue', sortable: false, width: '200px' },
  ]);

  let tableColumns = ref([
    { text: 'Date', value: 'date', width: '200px' },
    { text: 'Events', value: 'events', width: '200px' },
    { text: 'Merchanto Gross', value: 'merchantoGross', width: '200px' },
    { text: 'Merchanto Spend', value: 'merchantoSpend', width: '200px' },
    { text: 'Resellers Fee', value: 'resellerFee', width: '200px' },
    { text: 'Revenue per event', value: 'revenuePerEvent', width: '200px' },
    { text: 'Revenue', value: 'revenue', width: '200px' },
  ]);

  if (getProfile().role === RolesEnum.RESELLER) {
    tableTotalCountColumns = ref([
      { text: 'Total', value: 'total', sortable: false, width: '200px' },
      { text: 'Events', value: 'totalEvents', sortable: false, width: '200px' },
      { text: 'Resellers Fee', value: 'resellerFee', sortable: false, width: '200px' },
      { text: 'Resellers Fee per event', value: 'resellerFeePerEvent', sortable: false, width: '200px' },
    ]);

    tableColumns = ref([
      { text: 'Date', value: 'date', width: '200px' },
      { text: 'Events', value: 'events', width: '200px' },
      { text: 'Resellers Fee', value: 'resellerFee', width: '200px' },
      { text: 'Resellers Fee per event', value: 'resellerFeePerEvent', width: '200px' },
    ]);
  }

  if (getProfile().role === RolesEnum.MERCHANT || getProfile().role === RolesEnum.USER) {
    tableColumns = ref([
      { text: 'Date', value: 'date', width: '200px' },
      { text: 'Events', value: 'events', width: '200px' },
      { text: 'Cost', value: 'cost', width: '200px' },
    ]);
    tableTotalCountColumns = ref([
      { text: 'Total', value: 'total', sortable: false, width: '200px' },
      { text: 'Events', value: 'totalEvents', sortable: false, width: '200px' },
      { text: 'Total', value: 'totalCost', sortable: false, width: '200px' },
    ]);

  }

  const newDate = new Date();

  let timeoutFetchReport = null;

  const options = ref({
    sortBy: ['date'],
    sortDesc: [true],
  });
  const loading = ref(false);
  const groupBy = ref('createdAt');
  const websiteFilter = ref(null);
  const merchantFilter = ref(null);
  const serviceFilter = ref(null);
  const resellerFilter = ref(null);
  const managerFilter = ref(null);
  const periodDateFilter = ref([
    moment(newDate).startOf('month').format('YYYY-MM-DD'),
    moment(newDate).format('YYYY-MM-DD'),
  ]);
  const dateFilterType = ref('date');
  const periodDateModal = ref(null);
  const totalCounts = ref([]);
  const totalReportListTable = ref(null);
  const merchants = ref([]);
  const websites = ref([]);
  const resellerOptions = ref([]);
  const managerOptions = ref([]);
  const reportTotalLocal = ref([]);
  const selectedRows = ref([]);

  const presetsForPeriodDatePicker = [
    {
      title: 'Today',
      value: [moment(newDate).format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'Yesterday',
      value: [
        moment(newDate).subtract(1, 'days').format('YYYY-MM-DD'),
        moment(newDate).subtract(1, 'days').format('YYYY-MM-DD'),
      ],
    },
    {
      title: 'This Week',
      value: [moment(newDate).startOf('week').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: '7 Days',
      value: [moment(newDate).subtract(6, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'This Month',
      value: [moment(newDate).startOf('month').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'Last Month',
      value: [
        moment(newDate).subtract(1, 'month').startOf('month').format('YYYY-MM'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM'),
      ],
    },
    {
      title: '30 Days',
      value: [moment(new Date()).subtract(29, 'days').format('YYYY-MM-DD'), moment(newDate).format('YYYY-MM-DD')],
    },
    {
      title: 'Last 3 Months',
      value: [
        moment(newDate).subtract(3, 'month').startOf('month').format('YYYY-MM'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM'),
      ],
    },
    {
      title: 'Last 6 Months',
      value: [
        moment(newDate).subtract(6, 'month').startOf('month').format('YYYY-MM'),
        moment(newDate).subtract(1, 'month').endOf('month').format('YYYY-MM'),
      ],
    },
  ];

  /**
   * Functions
   */
  const fetchMerchants = () => {
    store
      .dispatch('user/fetchAll', {
        page: 1,
        role: RolesEnum.MERCHANT,
        itemsPerPage: '-1',
      })
      .then(response => {
        merchants.value = response.data.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Merchants',
          text: 'Cannot fetch merchants',
        });
      });
  };

  /**
   * Functions
   */
  const fetchWebsites = () => {
    store
      .dispatch('website/fetchWebsites')
      .then(response => {
        websites.value = response.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Websites',
          text: 'Cannot fetch websites',
        });
      });
  };

  const fetchResellers = () => {
    store
        .dispatch('user/fetchAll', {
          page: 1,
          role: RolesEnum.RESELLER,
          itemsPerPage: '-1',
        })
        .then(response => {
          resellerOptions.value = response.data.data;
        })
        .catch(() => {
          Vue.notify({
            type: 'error',
            title: 'Resellers',
            text: 'Cannot fetch resellers',
          });
        });
  };

  const fetchManagers= () => {
    store
      .dispatch('user/fetchAll', {
        page: 1,
        role: RolesEnum.ADMIN,
        itemsPerPage: '-1',
      })
      .then(response => {
        managerOptions.value = response.data.data;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Managers',
          text: 'Cannot fetch managers',
        });
      });
  };

  const fetchReport = () => {

    const periodDate = (groupBy.value === 'month') ? formatMonthPeriod(periodDateFilter.value) : periodDateFilter.value;

    const filters = {
      service: serviceFilter.value,
      resellerIds: resellerFilter.value,
      managerIds: managerFilter.value,
      websiteIds: websiteFilter.value,
      companyIds: merchantFilter.value,
      periodDate: periodDate,
      groupBy: groupBy.value,
      sortBy: options.value.sortBy[0],
      sortDesc: options.value.sortDesc[0],
      page: options.value.page,
      itemsPerPage: options.value.itemsPerPage,
    };

    store
      .dispatch('chargebackReport/fetchReport', {
        ...filters,
      })
      .then(response => {
        reportListTable.value = response.data.data;
        totalReportListTable.value = response.data.totalCount;
        totalCounts.value = [response.data.totalAmount];

        loading.value = false;
      })
      .catch(() => {
        Vue.notify({
          type: 'error',
          title: 'Events',
          text: 'Cannot fetch report',
        });
      });
  };

  const formatMonthPeriod = (monthRange) => {
    if (!monthRange) return;
    const formattedDates = monthRange.map((date, i) => {
      const [year, month] = date.split('-');
      const dayOfMonth = (i === 0) ? moment(`${year}-${month}-01`) : moment(`${year}-${month}`).endOf('month');
      return  moment(dayOfMonth).format('YYYY-MM-DD');
    });
    return formattedDates;
  };

  watch([options, merchantFilter, websiteFilter, serviceFilter, periodDateFilter, resellerFilter, managerFilter], () => {
    clearTimeout(timeoutFetchReport);
    if (groupBy.value !== 'month') {
      if (periodDateFilter.value && periodDateFilter.value[0].split('-').length < 3) {
        periodDateFilter.value = formatMonthPeriod(periodDateFilter.value);
      }
    }

    loading.value = true;
    selectedRows.value = [];
    timeoutFetchReport = setTimeout(() => {
      fetchReport();
    }, 1500);
  });

  watch([groupBy], () => {
    clearTimeout(timeoutFetchReport);
    loading.value = true;
    selectedRows.value = [];

    if (tableColumns.value.find(element => element.value === 'website') && tableColumns.value.find(element => element.value === 'tier')) {
      tableColumns.value.shift();
    }
    tableColumns.value.shift();

    dateFilterType.value = (groupBy.value === 'month') ? 'month' : 'date';

    if (periodDateFilter.value && periodDateFilter.value[0].split('-').length < 3) {
      periodDateFilter.value = formatMonthPeriod(periodDateFilter.value);
    }

    switch (groupBy.value) {
      case 'createdAt':
        options.value.sortBy[0] = 'date';
        tableColumns.value.unshift({ text: 'Date', value: 'date', width: '200px' });
        break;
      case 'month':
        periodDateFilter.value = [
          moment(newDate).subtract(5, 'months').startOf('month').format('YYYY-MM'),
          moment(newDate).endOf('month').format('YYYY-MM'),
        ];
        options.value.sortBy[0] = 'month';
        tableColumns.value.unshift({ text: 'Month', value: 'month', width: '200px' });
        break;
      case 'service':
        options.value.sortBy[0] = 'service';
        tableColumns.value.unshift({ text: 'Service', value: 'service', width: '200px' });
        break;
      case 'website':
        options.value.sortBy[0] = 'websiteName';
        tableColumns.value.unshift({ text: 'Website', value: 'websiteName', width: '200px' });
        break;
      case 'merchant':
        options.value.sortBy[0] = 'companyName';
        tableColumns.value.unshift({ text: 'Merchant', value: 'companyName', width: '200px' });
        break;
      case 'visaTier':
        options.value.sortBy[0] = 'tier';
        tableColumns.value.unshift({ text: 'Tier', value: 'tier', width: '200px' });
        break;
      case 'reseller':
        options.value.sortBy[0] = 'resellerName';
        tableColumns.value.unshift({ text: 'Reseller', value: 'resellerName', width: '200px' });
        break;
      case 'manager':
        options.value.sortBy[0] = 'managerName';
        tableColumns.value.unshift({ text: 'Manager', value: 'managerName', width: '200px' });
        break;
      case 'websiteTier':
        options.value.sortBy[0] = 'tier';
        tableColumns.value.unshift({ text: 'Tier', value: 'tier', width: '200px' });
        tableColumns.value.unshift({ text: 'Website', value: 'website', width: '200px' });
        break;
      default:
        break;
    }
    timeoutFetchReport = setTimeout(() => {
      fetchReport();
    }, 1500);
  });

  fetchMerchants();
  fetchWebsites();

  if (isAdmin) {
    fetchResellers();
    fetchManagers();
  }

  /**
   * Enums
   */
  const serviceOptions = eventServiceEnum;

  return {
    isAdmin,
    merchantFilter,
    websiteFilter,
    serviceFilter,
    periodDateFilter,
    periodDateModal,
    totalReportListTable,
    tableTotalCountColumns,
    totalCounts,
    merchants,
    websites,
    reportListTable,
    tableColumns,
    loading,
    options,
    groupBy,
    reportTotalLocal,
    selectedRows,
    serviceOptions,
    presetsForPeriodDatePicker,
    dateFilterType,
    resellerFilter,
    resellerOptions,
    managerFilter,
    managerOptions,
  };
}
